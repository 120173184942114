@media (max-width: 1400px) {
  .grid-2-resize{
    grid-template-columns: 1fr!important;
    gap: 4px!important;
    >*{
      grid-column: 1!important;
    }
  }
}

.grid-1{
  display: grid;
  grid-template-columns: 1fr!important;
  gap: 10px!important;
  >*{
    grid-column: 1!important;
  }
}

.grid-2-resize{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.grid-2{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.grid-auto-2{
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
}

.grid-3{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.grid-4{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
